<template>
  <div>
    <h1>Oops!</h1>
    <img
      src="https://media.tenor.com/images/32eb6c3ae48043387d29c5efb533e1af/tenor.gif"
      alt="Psyayayaya"
    />
    <h3>The {{ resource }} you're looking for is not here.</h3>
    <router-link :to="{ name: 'EventList' }">Back to the home page</router-link>
  </div>
</template>
<script>
export default {
  props: {
    resource: {
      type: String,
      required: true,
      default: 'page'
    }
  }
}
</script>
