<template>
  <div>
    <h1>Create an event</h1>
    <form @submit.prevent="sendForm">
      <BaseSelect
        :options="categories"
        v-model="event.category"
        label="Select a category:  "
      />

      <h3>Name your event</h3>

      <BaseInput v-model="event.title" lable="Title" type="text" />
      <br />
      <h3>Notes:</h3>

      <BaseInput v-model="event.description" type="text" />
      <h3>Where is your event?</h3>

      <BaseInput v-model="event.location" type="text" />
      <h3>When is your Event:</h3>
      <h5>Day (dd/mm/yy)</h5>
      <BaseInput v-model="event.date" type="text" />
      <h5>Time (24hr)</h5>
      <BaseInput v-model="event.time" type="text" />

      <h3>Are pets allowed?</h3>
      <div>
        <BaseRadioGroup
          v-model="event.pets"
          name="pets"
          :options="petOptions"
        />
      </div>

      <h3>Extras</h3>
      <div>
        <BaseCheckbox v-model="event.extras.catering" label="Catering" />
      </div>

      <div>
        <BaseCheckbox
          v-model="event.extras.entertainment"
          label="Live Entertainment"
        />
      </div>
      <div>
        <BaseCheckbox v-model="event.extras.remind" label="Remind Me" />
        <BaseSelect :options="Reminder" v-model="event.reminder" />
        <h5>Prior To The Event</h5>
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      categories: [
        'Celebration',
        'Journey/Trip',
        'Appointment',
        'Meet-Up',
        'Reminder',
        'Festive'
      ],
      Reminder: [
        '5 Minutes',
        '30 Minutes',
        '1 Hour',
        '3 Hours',
        '6 Hours',
        '12 Hours',
        '1 Day',
        '1 Week'
      ],
      event: {
        category: '',
        title: '',
        description: '',
        location: '',
        pets: 1,
        extras: {
          catering: false,
          music: false,
          reminder: false
        }
      },
      petOptions: [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 }
      ]
    }
  },
  methods: {
    sendForm() {
      axios
        .post(
          'https://my-json-server.typicode.com/amirgulubayli/ajson',
          this.event
        )
        .then(function(response) {
          console.log('Response', response)
        })
        .catch(function(err) {
          console.log('Error', err)
        })
    }
  }
}
</script>

<style scoped>
.horizontal {
  margin-right: 50px;
}
</style>
