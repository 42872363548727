<template>
  <div class="networkError">
    <h1>Uh-Oh!</h1>
    <img
      src="https://i.pinimg.com/originals/63/27/f8/6327f8573c578922d64763e0cbba1351.gif"
      alt="Waaaaaaaaa"
    />

    <h3>
      It looks like you're experiencing some network issues, please take a break
      and
      <a href="#" @click="$router.go(-1)">click here</a> to try again.
    </h3>
  </div>
</template>
