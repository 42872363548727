import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const GStore = reactive({ flashMessage: '' })

const app = createApp(App)
  .use(store)
  .use(router)
  .provide('GStore', GStore)

const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )
  console.log(fileName, componentName)

  app.component(componentName, componentConfig.default || componentConfig)
})

app.mount('#app')
